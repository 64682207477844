var app = new function(){
	this.modules = {};
	this.settings = {};
	this.vars = {
		screenMobile:true,
		screenMobileLandscape:false,
		screenTablet:false,
		screenTabletLandscape:false,
		screenDesktop:false,
		screenWide:false,
		screenFull:false,
		breakPoint:'mobile',
		isTouchDevice:false,
		ieVersion:false
	};
	this.init = function(){
		var that = this;

		// extend jquery
		jQuery.extend({
			getQueryParameters:function(str){
				return (str || document.location.search).replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = n[1],this}.bind({}))[0];
			}
		});
		
		this.setBreakPoint();
		this.resize();
		this.detectTouchDevice();
		var context = this;
		that.vars.ieVersion = that.msieversion();
		$.each(this.modules, function(index, element){
			if (typeof element.init === 'function'){
				element.init();
			}
		});
		this.parse($('body'));
		$(window).bind('load', function(){
			$.each(that.modules, function(index, element){
				if (typeof element.onLoad === 'function'){
					element.onLoad();
				}
			});
		});
	};
	this.parse = function($context){
		$.each(this.modules, function(index, element){
			if (typeof element.parse === 'function'){
				element.parse($context);
			}
		});
	};
	this.resize = function($context){
		var that = this;
		$(window).resize(function(){
			if (that.vars.isTouchDevice == false){
				that.setBreakPoint();
			}
		});
		$(window).on('orientationchange', function(event){
			that.setBreakPoint();
		});
		$.each(this.modules, function(index, element){
			if (typeof element.resize === 'function'){
				$(window).resize(function(){
					if (that.vars.isTouchDevice == false){
						element.resize();
					}
				});
				$(window).on('orientationchange', function(event){
					element.resize(event.orientation);
				});
			}
		});
		$(window).trigger('resize');
	};
	this.detectTouchDevice = function(){
		if (Modernizr.touch){
			app.vars.isTouchDevice = true;
		}
	};
	this.getViewport = function(type){
		var viewPortWidth;
		var viewPortHeight;
		if (typeof window.innerWidth != 'undefined'){
			viewPortWidth = window.innerWidth,
			viewPortHeight = window.innerHeight
		} else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0){
			viewPortWidth = document.documentElement.clientWidth,
			viewPortHeight = document.documentElement.clientHeight
		} else {
			viewPortWidth = document.getElementsByTagName('body')[0].clientWidth,
			viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
		}
		if (type == 'width'){
			return viewPortWidth;
		} else {
			return viewPortHeight;
		}
	};
	this.reinit = function($context){
		$.each(this.modules, function(index, element){
			if (typeof element.reinit === 'function'){
				element.reinit();
			}
		});
	};
	this.setBreakPoint = function(){
		var that = this;
		var windowWidth = that.getViewport('width');
		var breakPoint = 'mobile';
		app.vars.screenMobileLandscape = false;
		app.vars.screenTablet = false;
		app.vars.screenTabletLandscape = false;
		app.vars.screenDesktop = false;
		app.vars.screenWide = false;
		app.vars.screenFull = false;
		if (windowWidth >= 480){
			breakPoint = 'mobileLandscape';
			app.vars.screenMobileLandscape = true;
		}
		if (windowWidth >= 768){
			breakPoint = 'tablet';
			app.vars.screenTablet = true;
		}
		if (windowWidth >= 1024){
			breakPoint = 'tabletLandscape';
			app.vars.screenTabletLandscape = true;
		}
		if (windowWidth >= 1224){
			breakPoint = 'desktop';
			app.vars.screenDesktop = true;
		}
		if (windowWidth >= 1324){
			breakPoint = 'wide';app.vars.screenWide = true;
		}
		if (windowWidth >= 1600){
			breakPoint = 'full';
			app.vars.screenFull = true;
		}
		if (breakPoint != that.vars.breakPoint){
			that.vars.breakPoint = breakPoint;
		}
	};
	this.msieversion = function(){
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf("MSIE ");
		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){
			return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
		} else {
			return false;
		}
	};
	this.fixedContent = function(type){
		var htmlBody = $('html,body');
		var currentScrollTop = $(window).scrollTop();
		if (type == true && !htmlBody.hasClass('fixed')){
			htmlBody.addClass('fixed');
			$('.site').css({
				marginTop:currentScrollTop*-1
			});
			htmlBody.scrollTop(0);
		} else {
			var newScrollTop = parseInt($('.site').css('margin-top'));
			$('.site').attr('style','');
			htmlBody.removeClass('fixed');
			htmlBody.scrollTop(newScrollTop*-1);
		}
	};
};
$(function(){
	if (typeof $('.onepager-tile').html() !== 'undefined'){
		app.modules.onepager.init();
	} else {
		app.init();
	}
});

function _history(){
	this.historyLog = [];
	this.historyContent = [];
	this.vars = {
		historyLogIndex:-1,
		contentChanged:false,
		allowStateChange:false,
		module:null,
		userClicked:true,
		startUrl:this.relativeUrl(window.location.href),
		lastState:0,
		stateChanged:false
	}
}
_history.prototype = {
	init:function(){
		this.addListener();
		this.historyContent[this.relativeUrl(document.location.href)] = document.documentElement.outerHTML;
	},
	addListener:function(){
		var that = this;
		window.onpopstate = function(event){
			if (typeof event.isTrigger == 'undefined'){
				document.location.href = document.location.href;
			}
		};
	},
	loadPage:function(tile, selector, moduleName, moduleFunction){
		if (tile instanceof jQuery){
			var url = tile.attr('href');
		} else {
			var url = tile;
		}
		if (this.allowStateChange(url) === false && moduleName !== null){
			return false;
		}
		if (!window.History.enabled){
			document.location.href = url;
			return false;
		}
		var that = this;
		$.ajax({
			type:'GET',
			url:url,
			data:'',
			success:function(data){
				var $data = $('<i/>' + that.documentHtml(data));
				var $dataBody = $data.find('.document-body:first');
				var $dataTitle = $data.find('title').html();
				var $dataContent = selector == 'body' ? $dataBody.filter(':first') : $dataBody.find(selector).filter(':first');
				var contentHtml = $dataContent.html()||$data.html();
				if (!contentHtml){
					document.location.href = url;
					return false;
				}
				that.vars.module = moduleName;
				that.updateTitle(that.documentTitle(data));
				that.vars.contentChanged = true;
				if (that.vars.historyLogIndex < 0){
					var logStart = {url:that.vars.startUrl, module:moduleName, moduleFunction:moduleFunction, scrollTop:$(window).scrollTop()};
					that.historyLog.push(logStart);
					that.historyContent[that.relativeUrl(url)] = data;
					that.vars.historyLogIndex = 0;
				}
				var log = {url:url, module:moduleName, moduleFunction:moduleFunction, scrollTop:$(window).scrollTop()};
				that.historyLog.push(log);
				that.historyContent[that.relativeUrl(url)] = data;
				that.vars.historyLogIndex++;
				that.pushState(url, that.documentTitle(data));
				that.vars.contentChanged = false;
				if (moduleName !== null){
					app.modules[moduleName].pageLoaded(contentHtml);
				} else {
					that.pageLoaded(selector, contentHtml);
				}
				that.tracking(url);
			},
			error:function(jqXHR, textStatus, errorThrown){
				document.location.href = url;
				return false;
			}
		});
	},
	simulateStateChange:function(tile, moduleName, moduleFunction, title){
		if (tile instanceof jQuery){
			var url = tile.attr('href');
		} else {
			var url = tile;
		}
		if (!window.History.enabled) {
			document.location.href = url;
			return false;
		}
		var that = this;
		that.vars.module = moduleName;
		that.updateTitle(title);
		that.vars.contentChanged = true;
		var log = {url:url, module:moduleName, moduleFunction:moduleFunction, scrollTop:$(window).scrollTop()};
		that.historyLog.push(log);
		that.historyContent[that.relativeUrl(url)] = document.documentElement.outerHTML;
		that.vars.historyLogIndex++;
		that.pushState(url, title);
		that.vars.contentChanged = false;
		if (moduleName !== null){
			app.modules[moduleName].pageLoaded(null);
		}
		that.tracking(url);
	},
	pageLoaded:function(selector, contentHtml){
		$(selector).html(contentHtml);
		$.each(app.modules, function(index, element){
			$.each(element, function(indexSub, elementSub){
				if (elementSub instanceof jQuery){
					app.modules[index][indexSub] = $(elementSub.selector);
				}
			});
		});
		app.reinit();
		app.fixedContent(false);
	},
	documentHtml:function(html){
		var result = String(html).replace(/<\!DOCTYPE[^>]*>/i, '').replace(/<(html|head|body|title|meta|script)([\s\>])/gi,'<div class="document-$1"$2').replace(/<\/(html|head|body|title|meta|script)\>/gi,'</div>');
		return result;
	},
	documentTitle:function(html){
		var html = html.match(/<title[^>]*>([^<]+)<\/title>/);
		if (html === null){
			return '';
		} else {
			return html[1];
		}
	},
	pushState:function(url, title){
		var that = this;
		if (that.vars.stateChanged !== true){
			window.History.pushState(null, title, url);
			that.vars.stateChanged = false;
		}
		return false;
	},
	tracking:function(path){
		if (typeof ga !== 'undefined'){
			ga('set', {page:this.relativeUrl(path), title:document.title.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; ')});
			ga('send', 'pageview');
		}
	},
	relativeUrl:function(url){
		var	root = location.protocol+'//'+location.host,
			relativeUrl = url.replace(root, '').replace('./', '/');
		
		if (relativeUrl.charAt(0) != '/'){
			relativeUrl = '/'+relativeUrl;
		}
		return relativeUrl;
	},
	allowStateChange:function(url){
		var that = this;
		if (that.vars.userClicked == true){
			that.vars.userClicked = false;
			return true;
		}
		if (this.relativeUrl(url) != this.relativeUrl(window.location.href) || this.vars.allowStateChange == true){
			that.vars.allowStateChange = false;
			return true;
		} else {
			return false;
		}
	},
	updateTitle:function(text){
		document.title = text;
		try { document.getElementsByTagName('title')[0].innerHTML = document.title.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; '); }
		catch (Exception) { }
	}
};
app.modules._history = new _history;

function onepager(){
	this.vars = {
		sectionCount:0,
		sections:[],
		loadedPages:0,
		loadedPercentage:0,
		loadingComplete:false,
		loaded:false
	}
}
onepager.prototype = {
	init:function(){
		var that = this;
		that.addListener();

		// check for onepager sections
		if (typeof $('.onepager-tile').html() !== 'undefined' && that.vars.loaded == false){
			that.vars.loaded = true;
			var allSections = $('.onepager-tile');
			var sectionsOnepager = $('.onepager-tile[data-loaded="false"]');
			that.vars.sectionCount = sectionsOnepager.size();
			allSections.each(function(index){
				$('a[href="'+$(this).attr('data-url')+'"]').addClass('onepager-link');
			});
			sectionsOnepager.each(function(index){
				var section = $(this);
				var sectionUrl = section.attr('data-url')
				that.vars.sections.push(section);
				that.loadPage(sectionUrl, index);
			});
		}
	},
	addListener:function(){
		var that = this;

		// click onepager navi
		$('.onepager-link').unbind().on({
			click:function(){
				that.goToSection($(this));
				return false;
			}
		});
	},
	loadPage:function(url, index){
		var that = this;
		$.ajax({
			type:'GET',
			url:url,
			data:'',
			success:function(data){
				var $data = $('<i/>' + app.modules._history.documentHtml(data));
				var $dataBody = $data.find('.document-body:first');
				var $dataContent = $dataBody.find('.onepager-tile[data-loaded="true"]');
				var contentHtml = $dataContent.html()||$data.html();
				if (contentHtml){
					var contentHtmlObj = $('<div>'+contentHtml+'</div>');
					contentHtmlObj.find('header, footer').remove();
					contentHtml = contentHtmlObj.html();
					$('.onepager-tile[data-url="'+url+'"]').html(contentHtml);
					$dataContent.waitForImages({
						finished:function(){
							var newPercentageLoaded = Math.floor((100*that.vars.loadedPages)/that.vars.sectionCount);
							if (newPercentageLoaded > that.vars.loadedPercentage){
								that.vars.loadedPercentage = newPercentageLoaded;
							}
							that.loader(that.vars.loadedPercentage);
							that.vars.loadedPages++;
							if (that.vars.sectionCount == that.vars.loadedPages){
								that.finish();
							}
						},
						each:function(loaded, count, success){
							var currentPercentageLoaded = (100*that.vars.loadedPages)/that.vars.sectionCount;
							var division = Math.floor(100/that.vars.sectionCount);
							var sectionLoaded = Math.floor((division*loaded)/count);
							var newPercentageLoaded = Math.floor(currentPercentageLoaded+sectionLoaded);
							if (newPercentageLoaded > that.vars.loadedPercentage){
								that.vars.loadedPercentage = newPercentageLoaded;
							}
							that.loader(that.vars.loadedPercentage);
						},
						waitForAll:true
					});
				}
			}
		});
	},
	goToSection:function(link){
		var that = this;
		var section = $('.onepager-tile[data-url="'+link.attr('href')+'"]');
		if (app.modules._history.allowStateChange(link.attr('href'))){
			app.modules._history.simulateStateChange(link, null, null, section.attr('data-title'));
		}
		setTimeout(function(){
			$('.onepager-link').removeClass('active');
			$('.onepager-link[href="'+(link.attr('href'))+'"]').addClass('active');
			$('html,body').animate({
				scrollTop:section.offset().top-$('.module--navigation').outerHeight()
			}, 800, 'easeOutCubic', function(){
				//
			});
		}, 100);
	},
	reinit:function(){
		this.addListener();
	},
	finish:function(){
		var that = this;
		$('header').prependTo('.site__inner');
		$('footer').appendTo('.site__inner');
		setTimeout(function(){
			var curSectionOffsetTop = $('.onepager-tile[data-loaded="true"]').offset().top-$('.module--navigation').outerHeight();
			$('html,body').scrollTop(curSectionOffsetTop);
			if (curSectionOffsetTop > 0){
				setTimeout(function(){
					var naviNode = document.querySelector('.module--navigation');
					var naviTransform = new WebKitCSSMatrix(window.getComputedStyle(naviNode).webkitTransform);
					$('html,body').scrollTop(curSectionOffsetTop-naviTransform.m42);
				}, 400);
			}
			if ($.trim(window.location.hash) != '' && typeof $('.anchor[name="'+window.location.hash.replace('#', '')+'"]').attr('name') !== 'undefined'){
				var anchorObj = $('.anchor[name="'+window.location.hash.replace('#', '')+'"]');
				setTimeout(function(){
					$('html,body').scrollTop(anchorObj.offset().top);
				}, 410);
			}
			$('.module').removeClass('module--odd module--even').each(function(index){
				var module = $(this);
				if ((index+1) %2 == 0){
					module.addClass('module--even');
				} else {
					module.addClass('module--odd');
				}
			});
			that.vars.loadingComplete = true;
			that.loader(100);
		}, 100);
		$.each(app.modules, function(index, element){
			$.each(element, function(indexSub, elementSub){
				if (elementSub instanceof jQuery){
					app.modules[index][indexSub] = $(elementSub.selector);
				}
			});
		});
		app.init();
	},
	loader:function(percentage){
		var that = this;
		console.log(percentage);
	}
};
app.modules.onepager = new onepager;

function form(){
	this.vars = {
		loading:false,
		dependencyFieldStr:'input[data-dependencies],select[data-dependencies],option[data-dependencies],textarea[data-dependencies]'
	}
}
form.prototype = {
	init:function(){
		var that = this;
		that.addListener();
		that.replaceJcf();
		that.maskInput();
		that.formValidation();

		// check for dependencies
		$('select').trigger('change');
	},
	addListener:function(){
		var that = this;

		// select dependencies
		$('select').unbind().on({
			change:function(){
				that.checkForDependencies($(this));
			}
		});
	},
	replaceJcf:function(){
		jcf.setOptions('Select', {
			wrapNative:false,
			wrapNativeOnMobile:false,
			fakeDropInBody:false
		});
		jcf.replaceAll();
	},
	reinit:function(){
		var that = this;
		that.replaceJcf();
		that.maskInput();
		that.formValidation();
	},
	formValidation:function(){
		var that = this;

		// global ajax form submit
		$('form.form--validate').each(function(){
			var formObj = $(this);
			var moduleKey = formObj.find('input[name="moduleKey"]').val();
			var postId = formObj.find('input[name="postId"]').val();
			formObj.validate({
				rules:window['rules_'+moduleKey+'_'+postId],
				messages:window['messages_'+moduleKey+'_'+postId],
				invalidHandler:function(event, validator){
					if (typeof formObj.find('.form__row--messages').html() !== 'undefined'){
						var messageContainer = formObj.find('.form__row--messages');
						messageContainer.removeClass('hidden').addClass('form__row--error').html(window['message_error_'+moduleKey+'_'+postId]+'<ul></ul>');
						$.each(validator.invalid, function(index, obj){
							messageContainer.find('ul').append('<li>'+validator.invalid[index]+'</li>');
						});
					}
				},
				submitHandler:function(form){
					if (that.vars.loading == false){
						that.vars.loading = true;
						if (typeof formObj.attr('data-ajax-post') !== 'undefined' && formObj.attr('data-ajax-post') == 'true'){
							that.ajaxSubmit(formObj);
						} else {
							form.submit();
						}
					}
				}
			});
		});
	},
	ajaxSubmit:function(form){
		var that = this;
		that.form = form;
		var moduleKey = form.find('input[name="moduleKey"]').val();
		var postId = form.find('input[name="postId"]').val();
		form.addClass('form--loading');
		$.ajax({
			type:'POST',
			url:form.attr('action'),
			data:form.serialize(),
			error:function(jqXHR, exception){
				if (typeof form.find('.form__row--messages').html() !== 'undefined'){
					var messageContainer = form.find('.form__row--messages');
					messageContainer.removeClass('hidden').addClass('form__row--error').empty().html(window['message_error_'+moduleKey+'_'+postId]+'<ul></ul>');
					$.each(jqXHR.responseJSON, function(index, obj){
						messageContainer.find('ul').append('<li>'+obj[0]+'</li>');
					});
				}
				that.vars.loading = false;
				form.removeClass('form--loading');
			},
			success:function(data, textStatus, xhr){
				if (data.success == 'false'){
					if (typeof form.find('.form__row--messages').html() !== 'undefined'){
						var messageContainer = form.find('.form__row--messages');
						messageContainer.removeClass('hidden').addClass('form__row--error').empty().html(window['message_error_'+moduleKey+'_'+postId]+'<ul></ul>');
						$.each(data.error, function(index, obj){
							messageContainer.find('ul').append('<li>'+obj.message+'</li>');
						});
					}
				} else {
					if (typeof form.find('[name="callbackModule"]').val() !== 'undefined' && form.find('[name="callbackModule"]').val() != '' && typeof form.find('[name="callbackFunction"]').val() !== 'undefined' && form.find('[name="callbackFunction"]').val() != ''){
						form.find('.form__row--messages').removeClass('form__row--error').addClass('hidden').empty();
						var callbackModule = form.find('[name="callbackModule"]').val();
						var callbackFunction = form.find('[name="callbackFunction"]').val();
						app.modules[callbackModule][callbackFunction](form, data);
					} else {
						form.find('.form__row--messages').removeClass('form__row--error hidden').addClass('form__row--success').empty().html(window['message_success_'+moduleKey+'_'+postId]);
						form.find('*').not('.form__row--success, .form__row--success *').remove();
					}
				}
				that.vars.loading = false;
				form.removeClass('form--loading');
			}
		});
	},
	checkForDependencies:function(field){
		var that = this;
		fieldValue = field.val();
		fieldName = field.attr('name');
		currentForm = field.closest('form');
		var dependencyFields = currentForm.find(that.vars.dependencyFieldStr);
		dependencyFields.each(function(){
			var dependencyField = $(this);
			var dataDependency = dependencyField.attr('data-dependencies');
			if ($.trim(dataDependency) != ''){
				var dataDependencyArr = dataDependency.split(';');
				var evalStr = 'if (';
				$.each(dataDependencyArr, function(key, value){
					var operator = '==';
					var dependencyRuleArr = value.split('=');
					var dependencyFieldName = dependencyRuleArr[0];
					var dependencyFieldNameLastChar = dependencyFieldName.substr(dependencyFieldName.length-1);
					if (dependencyFieldNameLastChar == '!'){
						dependencyFieldName = dependencyFieldName.slice(0,-1);
						var operator = '!=';
					}
					var dependencyFieldValue = dependencyRuleArr[1];
					if (value == 'AND'){
						evalStr += " && ";
					} else if (value == 'OR'){
						evalStr += " || ";
					} else {
						evalStr += "$.trim(currentForm.find('[name=\""+dependencyFieldName+"\"]').val())"+operator+"'"+dependencyFieldValue+"'";
					}
				});
				evalStr += "){that.showField(dependencyField);}else{that.hideField(dependencyField);}";
				eval(evalStr);
			}
		});
	},
	showField:function(field){
		var that = this;
		var parentRow = field.closest('.form__row');
		var parentCol = field.closest('[class^="col-"]');
		if (field.is('option')){
			field.removeClass('hidden');
		} else {
			parentRow.removeClass('hidden');
			parentCol.removeClass('hidden');
		}
	},
	hideField:function(field){
		var that = this;
		var parentRow = field.closest('.form__row');
		var parentCol = field.closest('[class^="col-"]');
		if (field.is('option')){
			field.addClass('hidden');
		} else {
			// reset value
			if (field.is('select')){
				field.prop('selectedIndex', 0);
				that.replaceJcf();
			} else if (field.is(':checkbox')){
				field.attr('checked', false);
				that.replaceJcf();
			} else {
				field.val('');
			}

			if (parentRow.find('input,select,textarea').size() == 1){
				parentRow.addClass('hidden');
			} else {
				parentCol.addClass('hidden');
				if (parentRow.find('[class^="col-"]').size() > 1){
					if (parentRow.find('[class^="col-"]').size() == parentRow.find('[class^="col-"].hidden').size()){
						parentRow.addClass('hidden');
					}
				}
			}
		}
	},
	maskInput:function(){
		var maskInputFields = $('[data-mask]');
		maskInputFields.each(function(){
			var maskInputField = $(this);
			maskInputField.mask(maskInputField.attr('data-mask'));
		});
	},
	reinit:function(){
		this.init();
	}
};
app.modules.form = new form;

function header(){
	this.header = $('.module--header');
	this.navigation = $('.module--navi');
}
header.prototype = {
	init:function(){
		var that = this;
		that.addListener();
		that.resize();

		var headerSlider = new Swiper('.header__slider_container', {
			wrapperClass:'header__slider_wrapper',
			slideClass:'header__slider_slide',
			spaceBetween:0,
			slidesPerView:1,
			autoplay:3000,
			loop:true,
			effect:'fade',
			prevButton:'.header__slider_prev',
			nextButton:'.header__slider_next',
			onSlideChangeStart:function(swiper){
				//
			}
		});
	},
	addListener:function(){
		var that = this;

		// scroll down
		$('.header__down').unbind().on({
			click:function(){
				var newScrollPos = that.header.outerHeight()-100;
				$('html,body').animate({
					scrollTop:newScrollPos
				}, 800, 'easeOutCubic', function(){
					//
				});
				return false;
			}
		});
	},
	resize:function(){
		var that = this;

		// resize fullscreen header
		if (typeof that.header.html() !== 'undefined' && that.header.hasClass('module--header-full')){
			var headerHeight = app.getViewport('height');
			if (typeof that.navigation.html() !== 'undefined'){
				headerHeight = headerHeight-that.navigation.outerHeight();
			}
			that.header.css({
				height:headerHeight
			});
		}
	}

};
app.modules.header = new header;

(function( $ ) {
    $.fn.photoswipe = function(options){
        var galleries = [],
            _options = options;
        
        var init = function($this){
            galleries = [];
            $this.each(function(i, gallery){
                galleries.push({
                    id: i,
                    items: []
                });

                $(gallery).find('a').each(function(k, link) {
                    var $link = $(link),
                        size = $link.data('size').split('x');
                    if (size.length != 2){
                        throw SyntaxError("Missing data-size attribute.");
                    }
                    $link.data('gallery-id',i+1);
                    $link.data('photo-id', k);

                    var item = {
                        src: link.href,
                        msrc: link.children[0].getAttribute('src'),
                        w: parseInt(size[0],10),
                        h: parseInt(size[1],10),
                        title: $link.data('title'),
                        el: link
                    }
                        
                    galleries[i].items.push(item);
                    
                });

                $(gallery).on('click', 'a', function(e){
                    e.preventDefault();
                    var gid = $(this).data('gallery-id'),
                        pid = $(this).data('photo-id');
                    openGallery(gid,pid);
                });
            });
        }
        
        var parseHash = function() {
            var hash = window.location.hash.substring(1),
            params = {};

            if(hash.length < 5) {
                return params;
            }

            var vars = hash.split('&');
            for (var i = 0; i < vars.length; i++) {
                if(!vars[i]) {
                    continue;
                }
                var pair = vars[i].split('=');  
                if(pair.length < 2) {
                    continue;
                }           
                params[pair[0]] = pair[1];
            }

            if(params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            if(!params.hasOwnProperty('pid')) {
                return params;
            }
            params.pid = parseInt(params.pid, 10);
            return params;
        };
        
        var openGallery = function(gid,pid){
            var pswpElement = document.querySelectorAll('.pswp')[0],
                items = galleries[gid-1].items,
                options = {
                    index: pid,
                    galleryUID: gid,
                    getThumbBoundsFn: function(index) {
                        var thumbnail = items[index].el.children[0],
                            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                            rect = thumbnail.getBoundingClientRect(); 

                        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                    }
                };
            $.extend(options,_options);
            var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        }
        
        // initialize
        init(this);
        
        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = parseHash();
        if(hashData.pid > 0 && hashData.gid > 0) {
            openGallery(hashData.gid,hashData.pid);
        }
        
        return this;
    };
}( jQuery ));




function lightbox(){}
lightbox.prototype = {
	init:function(){
		var that = this;
		that.addListener();
	},
	addListener:function(){
		var that = this;

		// photoswipe
		$('.open_image').photoswipe({
	        history:false
	    });
	}
};
app.modules.lightbox = new lightbox;

function navi(){
	this.slider;
	this.main = $('.navi__main');
	this.mainMenu = $('.navi__mega_menu');
	this.burger = $('.navi__burger');
}
navi.prototype = {
	init:function(){
		var that = this;
		that.addListener();

		// init navi slider
		$('.navi__slider_container').each(function(){
			var swiperContainer = $(this);
			that.slider = new Swiper(swiperContainer[0], {
				wrapperClass:'navi__slider_wrapper',
				slideClass:'navi__slider_slide',
				slideActiveClass:'navi__slider_slide--active',
				prevButton:'.navi__slider_button--prev',
				nextButton:'.navi__slider_button--next',
				buttonDisabledClass:'navi__slider_button--disabled',
				slidesPerView:'auto',
				spaceBetween:30,
				onInit:function(swiper){
					//that.reinitSlider(swiper);
				},
				onSlideChangeStart:function(swiper){
					//that.reinitSlider(swiper);
				},
				onSlideChangeEnd:function(swiper){
					$(window).trigger('resize');
				}
			});
		});
	},
	addListener:function(){
		var that = this;

		// open/close subnav
		that.mainMenu.find('a.main').unbind().on({
			click:function(){
				var mainMenuItem = $(this);
				var parentListItem = mainMenuItem.parent();
				if (app.vars.screenTablet !== true && typeof parentListItem.find('ul').html() !== 'undefined'){
					var innerUl = parentListItem.find('> ul');
					if (innerUl.hasClass('show')){
						mainMenuItem.removeClass('selected');
						innerUl.removeClass('show');
					} else {
						mainMenuItem.addClass('selected');
						innerUl.addClass('show');
					}
					return false;
				} else {
					if (parentListItem.attr('data-has-children') == 'true'){
						if (!parentListItem.find('ul').hasClass('show')){
							var timeout = 0;
							if (typeof that.mainMenu.find('ul ul.show').html() !== 'undefined'){
								timeout = 80;
							}
							that.mainMenu.find('ul ul').removeClass('show');
							setTimeout(function(){
								parentListItem.find('ul').addClass('show');
							}, timeout);
						} else {
							parentListItem.find('ul').removeClass('show');
						}
						return false;
					}
				}
			}
		});
		$('.module--navi').bind('clickoutside', function(e){
			that.mainMenu.find('ul ul.show').removeClass('show');
		});

		// mobile navi
		that.burger.unbind().on({
			click:function(){
				if (that.mainMenu.hasClass('navi__main--open')){
					that.hideMobilenav();
				} else {
					that.showMobilenav();
				}
			}
		});
	},
	showMobilenav:function(){
		var that = this;
		that.burger.addClass('selected');
		that.mainMenu.addClass('navi__main--open');
		app.fixedContent(true);
	},
	hideMobilenav:function(){
		var that = this;
		that.burger.removeClass('selected');
		that.mainMenu.removeClass('navi__main--open');
		app.fixedContent(false);
	}
};
app.modules.navi = new navi;

function lazyLoad(){
	this.$slider;
	this.vars = {
		slidesPerView:3
	}
}
lazyLoad.prototype = {
	init:function(){
		var that = this;
		that.addListener();
		that.lazyLoadImages('[data-bg], img[data-src]');
	},
	addListener:function(){
		var that = this;
		$(window).on('scroll resize', function(){
			that.lazyLoadImages('[data-bg], img[data-src]');
		});
	},
	lazyLoadImages:function(selector){
		var that = this;
		setTimeout(function(){
			$(selector).each(function(i){
				var element = $(this);
				if (!element.hasClass('lazyload__loaded')){
					if (($(window).height()+$(window).scrollTop())-element.offset().top > 0 && ($(window).width()+$(window).scrollLeft())-element.offset().left > 0 && $(window).scrollTop() < (element.offset().top+element.outerHeight()) && $(window).scrollLeft() < (element.offset().left+element.outerWidth())){
						if (typeof element.attr('data-bg') !== 'undefined'){
							that.loadImage(element.attr('data-bg'), element, 'bg');
							element.css({
								'background-image':'url('+element.attr('data-bg')+')'
							});
						}
						if (typeof element.attr('data-src') !== 'undefined'){
							that.loadImage(element.attr('data-src'), element, 'src');
							element.attr('src', element.attr('data-src'));
						}
					}
				}
			});
		}, 10);
	},
	loadImage:function(url, element, type){
		$('<img src="'+url+'">').load(function(){
			element.addClass('lazyload__loaded');
		});
	}
};
app.modules.lazyLoad = new lazyLoad;

function image(){
	this.nav = $('.image__nav');
	this.sections = $('.image__block');
	this.sectionIds = [];
}
image.prototype = {
	init:function(){
		var that = this;
		that.addListener();

		// build section nav
		that.buildSectionNav();

		// trigger scroll
		$(window).trigger('scroll');
	},
	addListener:function(){
		var that = this;

		// click navi
		that.nav.find('span').unbind().on({
			click:function(){
				var clickSpan = $(this);
				var newScrollTopPos = that.sections.eq(clickSpan.attr('data-id')-1).offset().top;
				newScrollTopPos = newScrollTopPos-$('.module--navi').outerHeight();
				$('html,body').animate({
					scrollTop:newScrollTopPos
				}, 400, 'easeOutCubic', function(){
					//
				});
			}
		});
	},
	buildSectionNav:function(){
		var that = this;
		var countSections = that.sections.size();
		for(var i = 1; i <= countSections; i++){
			that.sectionIds.push(i);
			that.nav.append('<span data-id="'+i+'"></span>');
		}
		that.addListener();
		that.activeNav();
	},
	activeNav:function(){
		var that = this;
		$(window).scroll(function(){
			var windowPos = $(window).scrollTop();
			var windowHeight = $(window).height();
	        var docHeight = $(document).height();
	        for(var i = 0; i < that.sectionIds.length; i++){
	        	var divPos = that.sections.eq(i).offset().top - ((windowHeight/100)*50);
	        	var divHeight = that.sections.eq(i).outerHeight();
	        	if (windowPos >= divPos && windowPos < (divPos + divHeight)){
	        		that.nav.find('span').eq(i).addClass('current');
	            } else {
	                that.nav.find('span').eq(i).removeClass('current');
	            }
	        }
	        if (windowPos + windowHeight == docHeight){
	        	if (!that.nav.find('span:last-child').hasClass('current')){
	        		var navActiveCurrent = that.nav.find('span.current').attr('data-id');
	                that.nav.find('span[data-id="'+navActiveCurrent+'"]').removeClass('current');
	                that.nav.find('span:last-child').addClass('current');
	            }
	        }
	    });
	}
};
app.modules.image = new image;

function tabs(){
	this.vars = {
		pageSlug:null,
		html:'',
		tabs:[]
	}
}
tabs.prototype = {
	init:function(){
		var that = this;
		that.addListener();
		that.parseTabs();
	},
	addListener:function(){
		var that = this;

		// click tabs
		$('.tabs__link').unbind().on({
			click:function(){
				var tabsLink = $(this);
				app.modules._history.pushState(that.vars.pageSlug, document.title);
				app.modules._history.pushState(tabsLink.attr('href'), document.title);
				$('.tabs__page').removeClass('tabs__page--active');
				$('.tabs__page_'+tabsLink.attr('data-index')).addClass('tabs__page--active');
				$('.tabs__link').removeClass('active');
				$('.tabs__link[href="'+tabsLink.attr('href')+'"]').addClass('active');
				return false;
			}
		});
	},
	parseTabs:function(){
		var that = this;
		if ($('[id^="section-tab-"]').size() > 0){
			// get curent page slug
			that.vars.pageSlug = $('[id^="section-tab-"]:first').attr('data-page-slug');

			// go through all tab modules
			var tabsStr = '';
			$('.module--tab').each(function(){
				var tabModule = $(this);
				if (tabModule.attr('data-title') != 'stop'){
					that.vars.tabs.push({
						'title':tabModule.attr('data-title'),
						'slug':tabModule.attr('data-slug')
					});
				}
			});

			// build html markup
			if (that.vars.tabs.length > 0){
				$.each(that.vars.tabs, function(index, value){
					that.vars.html += '<li><a class="tabs__link" data-index="'+(index+1)+'" href="#!'+value.slug+'">'+value.title+'</a></li>';
				});

				// show tabs html markup
				$('[id^="section-tab-"]:not(.section-tab-end)').next().find('.module > div > div > div > .row > [class^="col-"]:last-child').prepend('<div class="tabs"><ul class="clean">'+that.vars.html+'</ul></div>');
				that.addListener();

				// disable not relevant sections
				var foundStarPoint = false;
				var sectionCount = 0;
				$('[id^="section-"]').each(function(index){
					var section = $(this);
					if (typeof section.attr('data-tab') !== 'undefined'){
						sectionCount++;
						foundStarPoint = true;
					}
					if (typeof section.attr('data-tab') !== 'undefined' && section.find('.module').attr('data-slug') == 'stop'){
						return false;
					}
					if (foundStarPoint){
						section.addClass('tabs__page tabs__page_'+sectionCount).attr('data-index', sectionCount);
						if (window.location.hash == '' && sectionCount == 1){
							section.addClass('tabs__page--active');
							$('.tabs__link').removeClass('active');
							$('.tabs__link[data-index="1"]').addClass('active');
						}
						if (window.location.hash != ''){
							var currentTabLink = $('.tabs__link[href="'+window.location.hash+'"]');
							$('.tabs__page_'+currentTabLink.attr('data-index')).addClass('tabs__page--active');

							$('.tabs__link').removeClass('active');
							$('.tabs__link[href="'+window.location.hash+'"]').addClass('active');
						}
					}
					/*
					if (foundStarPoint && typeof section.attr('data-tab') == 'undefined'){
						if (window.location.hash == '' && sectionCount == 1){
							//
						} else {
							section.addClass('tab-inactive-section');
						}
					}
					*/
				});
			}
		}
	}
};
app.modules.tabs = new tabs;

function backToTop(){}
backToTop.prototype = {
	init:function(){
		var that = this;
		that.addListener();
	},
	addListener:function(){
		var that = this;

		// back to top
		$('.module--back_to_top .button').unbind().on({
			click:function(){
				$('html,body').animate({
					scrollTop:0
				}, 800, 'easeInOutExpo', function(){
					//
				});
				return false;
			}
		});
	}
};
app.modules.backToTop = new backToTop;
